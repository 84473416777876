
import React, { useEffect, useState } from 'react'

export default function useDeviceDetect () {
  const [isMobile, setMobile] = useState(false)
  const [isTablet, setTablet] = useState(false)
  const [isMobileOnly, setMobileOnly] = useState(false)

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent
    // console.log('useDeviceDetect -> userAgent', userAgent)
    const mobileOnly = /iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)
    const tablet = Boolean(
      userAgent.match(
        /iPad|Tablet/i
      )
    ) || (typeof window.navigator !== 'undefined' && (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
    /* iPad OS 13 */

    const mobile = tablet || mobileOnly
    setTablet(tablet)
    setMobile(mobile)
    setMobileOnly(mobileOnly)
    // console.log('on effect', isMobile, isMobileOnly, isTablet)
  }, [])

  return { isMobile, isMobileOnly, isTablet }
}
