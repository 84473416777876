/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import useDeviceDetect from '../components/isMobile'
import useStore from '../utils/useStore'

function SEO ({ description, lang, meta, title }) {
  const isLoaded = useStore('loaded')
  const { isMobile } = useDeviceDetect()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
        class: `${isMobile && 'isMobile'}`
      }}
      title={title}
      titleTemplate={title}
      description={metaDescription}
    >
      <body className={!isLoaded ? 'loading' : undefined} />
      <meta property="og:url" content="https://jen.lu/" />
      <meta property="og:title" content="Jen Lu" />
      <meta property="og:description" content="Portfolio of Jen Lu, an experiential artist." />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="https://jen.lu/jenlu_social_share_1200x630.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@jendotlu" />
      <meta name="twitter:creator" content="@nvassalo"></meta>
      <meta property="twitter:description" content="Portfolio of Jen Lu, an experiential artist." />
      <meta name="twitter:image" content="https://jen.lu/jenlu_social_share_1200x630.jpg"></meta>
      { isMobile && <link rel="preload" as="video" href="videos/banner_access_m.mp4"></link>}
      { isMobile && <link rel="preload" as="video" href="videos/banner_retrieve_m.mp4"></link>}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: ''
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
}

export default SEO
