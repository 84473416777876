import create from 'zustand'
import { devtools } from 'zustand/middleware'

const [useStore] = create(devtools((set) => (
  {
    scrollValue: 0,
    setScrollValue: (v) => set((state) => ({ scrollValue: v })),

    documentHeight: 0,
    setDocumentHeight: (v) => set((state) => ({ documentHeight: v })),

    storeSpring: () => ({
      spring: {
        onRest: () => { }
      },
      setScroll: () => { }
    }),

    setStoreSpring: (v) => set((state) => ({ storeSpring: v })),

    thinking: false,
    setThinking: (v) => set((state) => ({ thinking: v })),

    index: null,
    setIndex: (v) => set((state) => ({ index: v })),

    isPhone: false,
    setPhone: (v) => set((state) => ({ isPhone: v })),

    started: false,
    setStarted: (v) => set((state) => ({ started: v })),

    isActive: false,
    setActive: (v) => set((state) => ({ isActive: v })),

    dotState: null,
    setDotState: (v) => set((state) => ({ dotState: v })),

    loaded: true,
    setLoaded: (v) => set((state) => ({ loaded: v })),

    introComplete: false,
    setIntro: (v) => set((state) => ({ introComplete: v })),

    waypointEnabled: true,
    setWaypointEnabled: v => set((state) => ({ waypointEnabled: v })),

    isMobile: false,
    setMobile: v => set((state) => ({ isMobile: v })),

    isMobileOnly: false,
    setMobileOnly: v => set((state) => ({ isMobileOnly: v })),

    isTablet: false,
    setTablet: v => set((state) => ({ isTablet: v }))

  })))

export default useStore
